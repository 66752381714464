import { Component, OnInit } from "@angular/core";

@Component({
  selector: "vex-printing-abx",
  templateUrl: "./printing-abx.component.html",
  styleUrls: ["./printing-abx.component.scss"],
})
export class PrintingABXComponent implements OnInit {
  html;
  time: Date = new Date();
  constructor() {}

  ngOnInit(): void {
    this.html = localStorage.getItem("toprint-abx");
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        localStorage.removeItem("toprint-abx");
        window.open("", "_self").close();
      }, 1000);
    }, 100);
  }
}
