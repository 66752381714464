import { StorageService } from '../shared/services/storage.service';
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private storage: StorageService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storage.get("auth.accesstoken");
        this.storage.get("auth.user");

        const tokenType = this.storage.get("auth.tokentype");
        if (token) {
            request = request.clone({
                setHeaders: {
                    // Authorization: `Bearer ${token}`,
                    'AccessToken': token,
                    'TokenType': tokenType
                },
            });
        }
        return next.handle(request);
    }
}
