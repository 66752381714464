import { Injectable } from '@angular/core';

export class Data {
    constructor(
    ) { }
}

@Injectable()
export class AppService {
    public Data = new Data(
    )
    public url = "http://localhost:4200/assets/data/";
    public getCountries() {
        return [
            { name: 'Malaysia' },
        ]
    }
    public getStates() {
        return [
            { name: 'Johor' },
            { name: 'Kedah' },
            { name: 'Kelantan' },
            { name: 'Kuala Lumpur' },
            { name: 'Labuan' },
            { name: 'Melaka' },
            { name: 'Negeri Sembilan' },
            { name: 'Pahang' },
            { name: 'Perak' },
            { name: 'Perlis' },
            { name: 'Pulau Pinang' },
            { name: 'Putrajaya' },
            { name: 'Sabah' },
            { name: 'Sarawak' },
            { name: 'Selangor' },
            { name: 'Terengganu' }
        ]
    }


} 