import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  localLink = "http://localhost:4200/#/"

  siteLink: any;
  clientLink: any;
  warehouseApi: any;
  orderApi: any;
  productApi: any;
  systemApi: any;
  userApi: any;

  baseUrl = ''; // environment.apiRoot;
  baseUrlLidt = ''; // environment.apiRoot;
  baseUrlCr = ''; // environment.apiRoot;
  // baseUrlUP = environment.apiRoot;
  // baseUrl = '';//environment.apiRoot;S

  public loading = false;
  public loadingMessage = 'Loading...';
  constructor(private http: HttpClient, private router: Router, private _route: ActivatedRoute) {

    if (window.location.href.includes('hazeltd.com')) {
      
      this.clientLink = "https://hazeltd.com/#/";
      this.siteLink = "https://admin.hazeltd.com/#/";
      this.warehouseApi = 'https://warehouse.hazeltd.com/public/';
      this.orderApi = 'https://order.hazeltd.com/public/';
      this.productApi = 'https://products.hazeltd.com/public/';
      this.systemApi = 'https://system.hazeltd.com/public/';
      this.userApi = 'https://user.hazeltd.com/public/';
    }else{     
      this.clientLink = "https://hazel.iwh2u.com/#/";
      this.siteLink = "https://hazel.admin.iwh2u.com/#/"
      this.warehouseApi = 'https://hazel.warehouse.iwh2u.com/public/';
      this.orderApi = 'https://hazel.order.iwh2u.com/public/';
      this.productApi = 'https://hazel.products.iwh2u.com/public/';
      this.systemApi = 'https://hazel.system.iwh2u.com/public/';
      this.userApi = 'https://hazel.user.iwh2u.com/public/';
    }
      
  }

  // GET
  public get(controller) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller);
  }
  public read(controller, id) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + id);
  }
  // CREATE
  create(controller, model) {
    return this.post(controller, model);
  }

  // Post
  postSimple(controller) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, {});
  }
  // Post
  post(controller, model) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, model);
  }

  public getAsync(controller): Observable<any> {
    this.loading = true;
    return this.http.get<any>(this.baseUrl + controller).pipe(tap((_) => console.log('Loaded')));
  }

  // list
  public list(listName) {
    this.loading = true;
    return this.http.get(this.baseUrl + 'list/' + listName);
  }

  // READ
  public getWithId(controller, model) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + model.id);
  }

  // Update
  public update(controller, model) {
    this.loading = true;
    return this.http.put(this.baseUrl + controller + '/' + model.id, model);
  }

  // Delete
  public delete(controller, model) {
    this.loading = true;
    return this.http.delete(this.baseUrl + controller + '/' + model.id);
  }
}
