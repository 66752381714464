import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-firebase-image",
  templateUrl: "./firebase-image.component.html",
  styleUrls: ["./firebase-image.component.scss"],
})
export class FirebaseImageComponent implements OnInit {
  @Input() image: string;
  @Output() imageUploaded = new EventEmitter<any>();
  @Input() label: string = "Upload";

  selectedFile: File = null;

  downloadURL: Observable<string>;
  constructor(private storage: AngularFireStorage) {}
  ngOnInit() {}
  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `RoomsImages/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`RoomsImages/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe((url) => {
            if (url) {
              this.image = url;
              this.imageUploaded.emit(this.image);
            }
            console.log(this.image);
          });
        })
      )
      .subscribe((url) => {
        if (url) {
          console.log(url);
        }
      });
  }
}
