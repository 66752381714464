import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ColorSearchPipe', pure: false })
export class ColorSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(color => {
        if (color.color)  {
          return color.color.search(searchText) !== -1;
        }
        // else{
        //   return user.email.search(searchText) !== -1;
        // }
      });
    }
  }
}