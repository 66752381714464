import { Component, OnInit } from "@angular/core";

@Component({
  selector: "vex-printing",
  templateUrl: "./printing.component.html",
  styleUrls: ["./printing.component.scss"],
})
export class PrintingComponent implements OnInit {
  html;
  time: Date = new Date();
  constructor() {}

  ngOnInit(): void {
    this.html = localStorage.getItem("toprint");
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        localStorage.removeItem("toprint");
        window.open("", "_self").close();
      }, 1000);
    }, 100);
  }
}
