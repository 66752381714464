import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-pre-order-setting',
  templateUrl: './pre-order-setting.component.html',
  styleUrls: ['./pre-order-setting.component.scss']
})
export class PreOrderSettingComponent implements OnInit {
  public form: FormGroup;
  registerForm: FormGroup;
  update :boolean =  false ; 
  po = {
    preparation_preorder_product_days:'',
    packaging_preorder_product_days:''
    
  }
  lang:any;
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,

  ) {
   }

  ngOnInit(): void {

    this.api.get(this.api.systemApi + 'preorder-product-setting-config').subscribe((res: any) =>{
      this.po = res;
    })
  
  }

  editPo(){
    this.update = !this.update;
  }
  updatePo(){
    this.api.create(this.api.systemApi + "preorder-product-setting-config", this.po).subscribe(res =>{
    this.update = !this.update;
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Configuration Updated',
      showConfirmButton: false,
      timer: 1500
    })
    })
  }
}
