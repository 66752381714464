// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiRoot: "https://user.iwh2u.com/public"

};

export const environmentCreate = {
  production: false,
  apiRoot: "https://user.iwh2u.com/public"

};
export const environmentUP = {
  production: false,
  apiRoot: "https://user.iwh2u.com/public"

};

export const environmentImage = {
  production: true,
  apiRoot: "http://localhost:5100",
  // apiRoot: "https://td-holidays.com/student",
  firebase: {
    apiKey: "AIzaSyDruDwMD91YZ7wH-nzSScEk46sp4jnODsc",
    authDomain: "hazeltd-9ad44.firebaseapp.com",
    databaseURL: "https://hazeltd-9ad44-default-rtdb.firebaseio.com",
    projectId: "hazeltd-9ad44",
    storageBucket: "hazeltd-9ad44.appspot.com",
    messagingSenderId: "326333384798",
    appId: "1:326333384798:web:d7cfbb211fc061aebc4ccf"
  },
  s3:
  {
    accessKeyId: 'AKIAXTCZPD3O7LKG7JNL',
    secretAccessKey: '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',
    region: 'ap-southeast-1'


    //  accessKeyId: 'AKIAXTCZPD3O6Y37AAMR',
    // secretAccessKey: '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',
    // region: 'ap-southeast-1'

  },
  AWS_BUCKET:'images.hazeltd.net',
  AWS_IMAGE_URL:'https://images.hazeltd.net/'
  // AWS_BUCKET:'images.hasnuri.net',
  // AWS_IMAGE_URL:'https://images.hasnuri.net/'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
