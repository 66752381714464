import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SubscriberSearchPipe', pure: false })
export class SubscriberSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(ads => {
        if (ads.user.user_name)  {
          return ads.user.user_name.search(searchText) !== -1;
        }
        else {
            // return ads.ads.title.search(searchText) !== -1;
        }
      });
    }
  }
}