import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class FirestoreFileService implements DataService {
  collection = "imagefiles";
  constructor(private firestore: AngularFirestore) { }
  find(key: string) {
    return this.firestore
      .collection(this.collection)
      .doc(key)
      .get()
      .toPromise()
      .then((res) => {
        return res.data();
      });
  }
  filter(key: string, value: string) {
    throw new Error("Method not implemented.");
  }
  get() {
    return this.firestore.collection(this.collection).snapshotChanges();
  }
  getByProduct$(productId) {
    return this.firestore.collection(this.collection, (ref) => ref.where("productId", "==", productId)).snapshotChanges();
  }
  getByProductBySize$(productId, size:string) {
    return this.firestore.collection(this.collection, (ref) => ref.where("productId", "==", productId).where("size", "==", size)).snapshotChanges();
  }


  getBySize$(size) {
    return this.firestore.collection(this.collection, (ref) => ref.where("size", "==", size)).snapshotChanges();
  }

  getByProduct(productId) {
    return this.firestore
      .collection(this.collection, (ref) => ref.where("productId", "==", productId)).snapshotChanges();
  }
  getByProductBySize(productId, size) {
    return this.firestore.collection(this.collection, (ref) => ref.where("productId", "==", productId).where("size", "==", size)).snapshotChanges();
  }

  //To get single image by index
  getOneByProductBySize(productId, size, index?: number) {
    if (!index) {
      index = 0;
    }
    return this.firestore
      .collection(this.collection, (ref) =>
        ref
          .where("productId", "==", productId)
          .where("size", "==", size)
          .orderBy("url")
          .startAt(index)
          .limit(1)
      )
      .snapshotChanges();
  }

  create(item: ImageFile) {
    return this.firestore.collection(this.collection).add(item);
  }
  update(item: ImageFile) {
    const id = item.id;
    delete item.id;
    return this.firestore.doc(this.collection + "/" + id).update({ ...item });
  }
 
  remove(itemId: string) {
    return this.firestore.doc(this.collection + "/" + itemId).delete();
  }


  //collection///
  getByCategory$(categoryId) {
    return this.firestore.collection(this.collection, (ref) => ref.where("categoryId", "==", categoryId)).snapshotChanges();
  }
  getByCategoryBySize$(categoryId, size:string) {
    return this.firestore.collection(this.collection, (ref) => ref.where("categoryId", "==", categoryId).where("size", "==", size)).snapshotChanges();
  }
  getByCollection(categoryId) {
    return this.firestore
      .collection(this.collection, (ref) => ref.where("categoryId", "==", categoryId)).snapshotChanges();
  }
  getByCategoryBySize(categoryId, size) {
    return this.firestore.collection(this.collection, (ref) => ref.where("categoryId", "==", categoryId).where("size", "==", size)).snapshotChanges();
  }
  getOneByCategoryBySize(categoryId, size, index?: number) {
    if (!index) {
      index = 0;
    }
    return this.firestore
      .collection(this.collection, (ref) =>
        ref
          .where("categoryId", "==", categoryId)
          .where("size", "==", size)
          .orderBy("url")
          .startAt(index)
          .limit(1)
      )
      .snapshotChanges();
  }
  createCategory(item: CategoryImageFile) {
    return this.firestore.collection(this.collection).add(item);
  }
  updateCategory(item: CategoryImageFile) {
    const id = item.id;
    delete item.id;
    return this.firestore.doc(this.collection + "/" + id).update({ ...item });
  }
}




export interface ImageFile {
  id: string | null;
  // sortId: number | 0;
  productId: number;
  original: string;
  size80: string;
  size250: string;
  size350: string;
  size560: string;
  size800: string;
}
export interface CategoryImageFile {
  id: string | null;
  categoryId: number;
  original: string;
  size80: string;
  size250: string;
  size350: string;
  size560: string;
  size800: string;
}

// export interface ImageFile {
//   id: string | null;
//   productId: number;
//   url: string;
//   size: string;
// }