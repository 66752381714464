import { LoginResponse } from './../../@models/login-response';
import { StorageService } from '../../shared/services/storage.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2'
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  lang: any;
  constructor(public translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    private auth: AuthService, private storage: StorageService,
    public snackBar: MatSnackBar,
    private router: Router) {

  }

  ngOnInit(): void {
    if (this.auth.loggedIn()) {
      this.router.navigate(["/"])
    }
    this.lang = 'eng';
    if (localStorage.getItem("leng") === null) {
      localStorage.setItem("leng", 'eng');
      this.lang = 'eng';
    } else {
      this.lang = localStorage.getItem("leng");
    }

    this.translate.setDefaultLang(this.lang);

  }

  login() {
    this.auth.login(this.email, this.password).then((res: any) => {

      if (res.user.role.role === "Customer" || res.user.role.role === "Guest" || res.user.role.role === "Agent" || res.user.role.role === "CAE" || res.user.role.role === "CAM" || res.user.role.role === "Team Sale") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Access denied',
          showConfirmButton: false,
          timer: 1500
        })
        localStorage.clear();
        localStorage.setItem("leng", 'eng');

      } else {
        this.afterLogin(res)
      }

    }, error => {
      //later show error
      if (error) {
        this.email === ''
        this.password === ''
      }
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Email or password incorrect ',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }


  afterLogin(res) {
    this.storage.set("auth", res)
    this.router.navigate(["/"])

  }

  useLanguage(language: string) {
    localStorage.setItem("leng", language);
    this._document.defaultView.location.reload();
  }

}
