import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { AdminComponent } from "./admin/admin.component";
import { LoginComponent } from "./admin/login/login.component";
import { GuestGuard } from "src/app/@guards/guest.guard";
import { AuthGuard } from "src/app/@guards/auth.guard";
import { PrintingComponent } from "./admin/printing/printing.component";
import { PrintingABXComponent } from "./admin/printing-abx/printing-abx.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent },
  { path: "print", component: PrintingComponent },
  { path: "print-abx", component: PrintingABXComponent },
  //   { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: "enabled", // for one load page, without reload
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
