import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreFileService, ImageFile } from 'src/app/@data-services/firestore-file.service';
import { InventoryData } from 'src/app/admin/products/product-inventory/product-inventory.component';
import { ApiService } from '../../services/api.service';


import { ProductInventoryLocationData } from '../../store/product/interface/product-location';
import { ProductInventoryOutInfo } from '../../store/product/interface/product-out-info';

import { ProductListResult, ProdcutListData } from '../../store/product/interface/product-list';

// interface ProductResult {
//   current_page: number;
//   data: ProdcutListData[];
//   first_page_url: string;
//   from: number;
//   last_page: number;
//   last_page_url: string;
//   next_page_url: string;
//   path: string;
//   per_page: number;
//   prev_page_url?: any;
//   to: number;
//   total: number;
// }

// interface ProdcutData {
//   id: number;
//   brand_id: number;
//   brand: string;
//   product_sku: string;
//   title: string;
//   short_description: string;
//   description: string;
//   additional_description: string;
//   price_display: number;
//   price_b2c: number;
//   price_b2b: number;
//   price_b2a: number;
//   price_b2s: number;
//   price_b2d: number;
//   price_b2p: number;
//   staff_commission: number;
//   weight: number;
//   staff_group: number;
//   rating: number;
//   publish_status: number;
//   featured_product: number;
//   status: number;
//   created_by: number;
//   updated_by: number;
//   created_at: string;
//   updated_at: string;
//   colors: Color[];
//   sizes: Size[];
//   categories: (Categories | null)[];
//   inventories: Inventory[];
//   selected: boolean | false;
//   image: any
// }

// interface Inventory {
//   current_stock: number;
//   color_id: number;
//   color: string;
//   size_id: number;
//   size: string;
// }

// interface Categories {
//   id: number;
//   category: string;
//   description: string;
//   is_featured: number;
//   status: number;
//   created_by: number;
//   created_at: string;
//   updated_at: string;
// }

// interface Size {
//   size_id: number;
//   size: string;
//   description: string;
// }

// interface Color {
//   color_id: number;
//   color: string;
// }


@Component({
  selector: 'app-search-add',
  templateUrl: './search-add.component.html',
  styleUrls: ['./search-add.component.scss']
})
export class SearchAddComponent implements OnInit {
  @Input() add: boolean;
  @Input() search: boolean;
  @Input() searchType: string;
  @Input() dateTo;
  @Input() dateFrom: string;
  @Input() pageId: 1;
  @Input() statusFilter: string = '';
  @Input() categoryFilter: string = '';


  @Output() newData = new EventEmitter();
  // @Input() searchText: string;


  product: ProductListResult;
  products: ProdcutListData[];

  searchText = '';
  // statusFilter: string = '';
  // pageId = 1;
  // categoryFilter: string = '';
  nonCategoryDiv = true;
  categoryDiv = false;

  totalPage: number;
  lastPage: any;
  currentPage: any;
  perPage: any;
  previous = 0;
  pages: any[] = [];


  isSearch: boolean = false;
  stock: InventoryData[];
  inventoryLocationData: ProductInventoryLocationData[];
  inventoryOutList: any
  totalOrders;
  inventoryOutInfos: ProductInventoryOutInfo[] = []
  sortedData: ProductInventoryOutInfo[];
  stocks: any;

  constructor(
    private api: ApiService,
    private firestoreFileService: FirestoreFileService,
    private router: Router,

  ) { }

  ngOnInit(): void {
  }

  paginate(c, m) {
    this.currentPage = c;
    var delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);
    for (let i = c - delta; i <= c + delta; i++) {
      if (i < m && i > 1) {
        range.push(i);
      }
    }
    range.push(m);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  firstPageChanged(page, index) {
    if (page == "...") {
      const low = this.pages[index - 1];
      const high = this.pages[index + 1];
      const mid = Math.round((high - low) / 2) + low;
      this.pages = this.paginate(mid, this.totalPage);
    } else {
      this.pages = this.paginate(page, this.totalPage);
    }
  }


  productSearch(searchKey, event, typeSearch) {
    if (event.key === "Enter") {
      if (searchKey.length >= 3) {
        this.searchText = searchKey;
        this.filterSearch(typeSearch);
        // this.getProduct();
      } else {
        this.filterSearch(typeSearch);
        // this.getProduct();
      }
    }
  }

  productSearchEnter(searchKey, typeSearch) {
    if (searchKey.length >= 3) {
      this.searchText = searchKey;
      this.filterSearch(typeSearch);
      // this.getProduct();
    } else {
      this.filterSearch(typeSearch);
      // this.getProduct();
    }
  }

  goToProduct() {
    this.router.navigate(["products/add-product/"]);
  }

  getProduct() {
    var urlApi = this.api.productApi + 'products?page=' + this.pageId + '&search=' + this.searchText + '&status=' + this.statusFilter + '&category=' + this.categoryFilter;

    this.api.get(urlApi).subscribe((res: ProductListResult) => {

      if (this.nonCategoryDiv === true) {
        this.products = res.data;
        this.lastPage = res.last_page;
        this.currentPage = res.current_page;
        this.perPage = res.per_page;
        this.totalPage = res.last_page;

        let item = {
          product: this.products,
          lastPage: this.lastPage,
          currentPage: this.currentPage,
          perPage: this.perPage,
          totalPage: this.totalPage
        }

        this.newData.emit(item);

        this.firstPageChanged(1, 1);
        // this.firestoreFileService.get().subscribe(data => {
        //   const images = data.map((e: any) => {
        //     return {
        //       id: e.payload.doc.id,
        //       ...e.payload.doc.data(),
        //     } as ImageFile;
        //   })
        //   this.products.forEach(product => {
        //     const image = images.find(i => i.productId == product.id);

        //     product.image = image ? image.size80 : null;
        //   })
        // })
        
        this.products.forEach(product => {
          product.image = product.images && product.images.length && product.images[0].image_80||'';
        })
      } else {
        this.products = res.data;
        this.products.forEach(product => {
          product.image = product.images && product.images.length && product.images[0].image_80||'';
        })
        // this.firestoreFileService.get().subscribe(data => {
        //   const images = data.map((e: any) => {
        //     return {
        //       id: e.payload.doc.id,
        //       ...e.payload.doc.data(),
        //     } as ImageFile;
        //   })
        //   this.products.forEach(product => {
        //     const image = images.find(i => i.productId == product.id);

        //     product.image = image ? image.size80 : null;
        //   })
        // })
      }
    })
  }

  filterInventory() {
    var urlApi = this.api.productApi + 'product-inventory?page=' + this.pageId + '&search=' + this.searchText + '&category=' + this.categoryFilter;

    this.api.get(urlApi).subscribe((res: any) => {
      if (this.nonCategoryDiv === true) {

        this.stock = res.data;
        // console.log(this.stock)
        this.lastPage = res.last_page;
        this.currentPage = res.current_page;
        this.totalPage = res.last_page;


        let item = {
          stock: this.stock,
          lastPage: this.lastPage,
          currentPage: this.currentPage,
          totalPage: this.totalPage
        }

        this.newData.emit(item);


        this.firstPageChanged(1, 1);
        this.firestoreFileService.get().subscribe(data => {
          const images = data.map((e: any) => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data(),
            } as ImageFile;
          })
          this.stock.forEach(s => {
            const image = images.find(i => i.productId == s.id);

            s.image = image ? image.size80 : null;
          })
        })
      } else {
        this.stock = res.data;
        // console.log(this.stock)
        this.firestoreFileService.get().subscribe(data => {
          const images = data.map((e: any) => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data(),
            } as ImageFile;
          })
          this.stock.forEach(s => {
            const image = images.find(i => i.productId == s.id);

            s.image = image ? image.size80 : null;
          })
        })

      }
    })

  }

  filterProductLocation() {
    var urlApi = this.api.productApi + 'inventory-location?page=' + this.pageId + '&search=' + this.searchText;

    this.api.get(urlApi).subscribe((res: any) => {
      this.inventoryLocationData = res.data;
      this.lastPage = res.last_page;
      this.currentPage = res.current_page;
      this.totalPage = res.last_page
      this.firstPageChanged(1, 1);

    })
  }

  filterInventoryOut(fromDate?, toDate?) {
    var urlApi = this.api.orderApi + 'shipout-billcode?page=' + this.pageId + '&search=' + this.searchText + '&from=' + fromDate + '&to=' + toDate;
    this.api.get(urlApi).subscribe((res: any) => {
      this.inventoryOutList = res.data
      this.totalOrders = res.total
      this.perPage = res.per_page;
      this.lastPage = res.last_page;
      this.currentPage = res.current_page;
      this.totalPage = res.last_page;

      let item = {
        inventoryOutList: this.inventoryOutList,
        totalOrders: this.totalOrders,
        lastPage: this.lastPage,
        currentPage: this.currentPage,
        perPage: this.perPage,
        totalPage: this.totalPage
      }

      this.newData.emit(item);
      this.firstPageChanged(1, 1);
    })
  }

  filterOutInfo(fromDate?, toDate?) {
    this.api.get(this.api.orderApi + "inventory-out-products?from=" + fromDate + '&to=' + toDate).subscribe((res: any) => {
      this.inventoryOutInfos = res;
      this.sortedData = this.inventoryOutInfos.slice();

      let item = {
        inventoryOutList: this.inventoryOutInfos,
        sortedData: this.sortedData,
      }

      this.newData.emit(item);

    })
  }

  filterInventoryUpdate(fromDate?, toDate?) {
    var urlApi = this.api.warehouseApi + "entry-stock?page=" + this.pageId + '&sku=' + this.searchText + '&from=' + fromDate + '&to=' + toDate;
    this.api.get(urlApi).subscribe((res: any) => {
      this.stocks = res.data
      this.perPage = res.per_page;
      this.lastPage = res.last_page;
      this.currentPage = res.current_page
      this.totalPage = res.last_page
      this.firstPageChanged(1, 1);
    })
  }

  filterSearch(type) {
    switch (type) {
      case 'list':
        this.getProduct();
        break;

      case 'inventory':
        this.filterInventory();
        break;

      case 'location':
        this.filterProductLocation();
        break;

      case 'out':
        this.filterInventoryOut(this.dateFrom, this.dateTo)
        break;

      case 'outInfo':
        this.filterOutInfo(this.dateFrom, this.dateTo);
        break;

      case 'update':
        this.filterInventoryUpdate(this.dateFrom, this.dateTo);
        break;
    }
  }

  toggleBtn() {
    if (this.isSearch == true) {
      this.isSearch = false;
    } else {
      this.isSearch = true;
    }
  }

  clearText() {
    this.searchText = '';
    this.filterSearch(this.searchType)
  }
}
