import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dropship-setting',
  templateUrl: './dropship-setting.component.html',
  styleUrls: ['./dropship-setting.component.scss']
})
export class DropshipSettingComponent implements OnInit {
  public form: FormGroup;
  registerForm: FormGroup;
  update :boolean =  false ; 
  ds = {
    min_topup_value_to_be_partner:'',
    min_topup_value_to_be_prepartner:'',
    add_commission_partner:'',
    add_commission_pre_partner:'',
    bonus_commission_partner:'',
    bonus_commission_pre_partner:''
    
  }
  lang:any;
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,

  ) {
   }

  ngOnInit(): void {

  

    this.api.get(this.api.systemApi + 'ds-setting-config').subscribe((res: any) =>{

      this.ds = res;
      
      })
  
  }

  editDs(){
    this.update = !this.update;
  }
  updateDs(){
    this.api.create(this.api.systemApi + "ds-setting-config", this.ds).subscribe(res =>{
    this.update = !this.update;
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Configuration Updated',
      showConfirmButton: false,
      timer: 1500
    })
    })
  }
}
